// extracted by mini-css-extract-plugin
export var relativeWrapper = "news-module--relativeWrapper--1OwnY";
export var decor = "news-module--decor--1yn7i";
export var hamburger = "news-module--hamburger--1NkCf";
export var units = "news-module--units--1nDld";
export var reactCalendarMonthViewDays = "news-module--react-calendar__month-view__days--19KoM";
export var reactCalendarTile = "news-module--react-calendar__tile--XpB6l";
export var reactCalendarTileActive = "news-module--react-calendar__tile--active--hgfpM";
export var container = "news-module--container--2s1Tl";
export var newsFeatureContainer = "news-module--newsFeatureContainer--DlcDY";
export var newsFeatureContent = "news-module--newsFeatureContent--1FuY4";
export var newsAuthorBlock = "news-module--newsAuthorBlock--2Cb-O";
export var newsFeatureMedia = "news-module--newsFeatureMedia--2HTy3";
export var newsPostContainer = "news-module--newsPostContainer--2cyxB";
export var newsPostList = "news-module--newsPostList--1FlZZ";
export var newsMore = "news-module--newsMore--2Ejqa";
export var fadedPatternBoxTopWrapper = "news-module--fadedPatternBoxTopWrapper--3-BXF";
export var fadedPatternBoxTop = "news-module--fadedPatternBoxTop--1QDd_";
export var sidelines = "news-module--sidelines--1OFIq";
export var stop = "news-module--stop--rOibo";
export var dark = "news-module--dark--cZtSF";
export var topLineHeader = "news-module--topLineHeader--1WMKN";
export var bottomPattern = "news-module--bottomPattern--DuHrB";