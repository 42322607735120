import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "gatsby";

import NewsPost from "@arbol/NewsPost";

const PAGE_SIZE = 18;

function IndexPage({ newsPosts, styles, CustomNewsMore }) {
  const [currentIndex, setCurrentIndex] = useState(1);
  const handleMore = () => setCurrentIndex(currentIndex + 1);

  const features = newsPosts.filter(obj => {
    return obj.data.featured == true;
  });

  const feature = features[0] || newsPosts[0];
  const featureUrl = feature.data?.external_link?.url ?? feature.data.title.text.trim().replace(/\s/g, '-').replace(/[^0-9a-z-]/gi, '');
  const target = feature.data?.external_link?.target ?? "_self";
  return (
    <>
      <div className={styles.container}>
        <div className={styles.relativeWrapper}>
          <div className={styles.newsFeatureContainer}>
            <div className={styles.newsFeatureContent}>
              <h2>{feature.tags}</h2>
              <h1>{feature.data.intro_text.text}</h1>
              <Link target={target} to={featureUrl}>Read More</Link>
              <div className={styles.newsAuthorBlock}>
                {
                  feature.data.author_image.url &&
                  <img src={feature.data.author_image.url} />
                }
                {
                  feature.data.author.text &&
                  <p>by {feature.data.author.text}</p>
                }
              </div>
            </div>
            <div className={styles.newsFeatureMedia}>
              <img src={feature.data.main_image.url} />
            </div>
          </div>
          <div className={styles.newsPostContainer}>
            <h2>Recent News</h2>
            <div className={styles.newsPostList}>
              {newsPosts.filter(e => !e.data.featured).slice(0, PAGE_SIZE * currentIndex).map((item, index) => (
                <NewsPost key={index} item={item} className={styles.newsPost} />
              ))}
            </div>
            <div className={styles.newsMore}>
              {(PAGE_SIZE * currentIndex) < newsPosts.length && (
                <a id="loadMoreNews" onClick={() => handleMore()}>
                  {
                    CustomNewsMore ?
                      <CustomNewsMore />
                      :
                      <>
                        LOAD MORE NEWS
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="42"
                          height="1"
                          viewBox="0 0 42 1"
                        >
                          <line
                            id="Line_127"
                            data-name="Line 127"
                            x2="42"
                            transform="translate(0 0.5)"
                            fill="none"
                            stroke="#c7a374"
                            strokeWidth="1"
                          />
                        </svg>
                      </>
                  }
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

IndexPage.propTypes = {
  newsPosts: PropTypes.array.isRequired,
  styles: PropTypes.object.isRequired,
  CustomNewsMore: PropTypes.func
};

IndexPage.defaultProps = {
  CustomNewsMore: undefined
};

export default IndexPage;
