import React from 'react';
import * as styles from './news.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import Layout from '@envy/Layout';
import Footer from '@envy/Footer';
import ContactForm from '@envy/ContactForm';
import NewsPageBase from '@components/NewsPage';
import Seo from '@components/seo';

function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      envyNews: allPrismicNewsPost(
        filter: { data: { page_selection: { eq: "Envy" } } }
        sort: { order: DESC, fields: last_publication_date }
      ) {
        nodes {
          tags
          id
          data {
            featured
            external_link {
              url
              target
            }
            title {
              text
            }
            intro_text {
              text
            }
            author {
              text
              html
            }
            author_image {
              url
            }
            main_image {
              url
            }
            small_image {
              url
            }
            page_selection
          }
        }
      }
    }
  `);

  const newsPosts = data.envyNews.nodes;

  return (
    <Layout transparentHeader={false}>
      <Seo
        title="News | Envy by Gomes"
        description="News and events happening at Envy, Newark New Jersey’s newest luxury apartment concept inspired by elegance and grandeur."
      />
      <div className={styles.fadedPatternBoxTopWrapper}>
        <div className={styles.fadedPatternBoxTop} />
      </div>
      <NewsPageBase newsPosts={newsPosts} styles={styles} />
      <div className={classNames(styles.dark, styles.bottomPattern)}>
        <ContactForm />
        <div className={styles.relativeWrapper} style={{ padding: 0 }}>
          <Footer style={{ marginTop: '104px' }} />
        </div>
        <div className={classNames(styles.sidelines)} />
      </div>
    </Layout>
  );
}

export default IndexPage;
