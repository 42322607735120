import React from "react";
import PropTypes from "prop-types";
import * as styles from "./NewsPost.module.scss";
import { Link } from "gatsby";
import classNames from "classnames";

function NewsPost({ item, className }) {
  let postUrl = item.data.title.text.trim().replace(/\s/g, '-').replace(/[^0-9a-z-]/gi, '');
  postUrl = `/property/arbol/news/${postUrl}`;
  let targetBlank = false;

  if (item.data?.external_link?.url) {
    postUrl = item.data.external_link.url;
    targetBlank = item.data.external_link.target === "_blank";
  } else if (item.data?.link?.url) {
    postUrl = item.data.external_link.url;
    targetBlank = item.data.external_link.target === "_blank";
  }

  return (
    <Link
      className={classNames(styles.newsPostWrapper, className)}
      to={postUrl}
      target={targetBlank ? '_blank' : undefined}
    >
      <div className={styles.newsMedia}>
        <img src={item.data.main_image.url} />
      </div>
      <div className={styles.newsContent}>
        <h1>{item.tags}</h1>
        <p>{item.data.title.text}</p>
      </div>
    </Link>
  );
}

NewsPost.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
};

NewsPost.defaultProps = {
  item: { tags: "", data: {} },
  className: "",
};

export default NewsPost;
